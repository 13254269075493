<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            helpers.monthName,
            { key: 'month_id', _classes: 'hide' },
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'),
            helpers.ownerName,
            'consecutive',
            { ...this.helpers.thousandValue, key: 'value' },
            { ...this.helpers.thousandValue, key: 'discount' },
            { ...this.helpers.thousandValue, key: 'discount_value' },
            ...dynamic_fields
          ]"
          :config="{
            name: 'invoice',
            url: 'admin/invoices/formats',
            route: '/admin/invoicing/formats',
            display_name: 'Planilla de Facturación',
            //params: { _whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units' },
            filters: [{ key: 'month_id', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData }], //, { key: 'imputation_name', label: 'Imputation' }, { key: 'building_code', use: 'building.identifier' }, 'building_type', 'property_unit', { key: 'grouper_code', use: 'grouper.identifier' }, 'owner_name'],
            noCreate: true,
            noOptions: 'hide',
            parseItems
          }"
          :sorterValue="{ column: 'consecutive', asc: false }"
          @dataChanged="onDataChanged"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Formats',
  mixins: [crudMixin],
  data() {
    return {
      month: {},
      dynamic_fields: []
    }
  },
  methods: {
    onGetData(month_id) {
      if (month_id) {
        this.month = { month_id, _period_name: this.getPeriodName(month_id) }
        this.$refs.crud_table.getData(false, { month_id }, true)
      }
    },
    onDataChanged() {
      //
    },

    parseItems(parent, items) {
      this.dynamic_fields = []
      const admon_columns = {}
      const charges_columns = { CEX: {}, CBP: {}, CBR: {} }
      const config = this.$refs.crud_table.response.data.config

      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - Plantilla de Facturacion ' + this.getPeriodName(this.$refs.crud_table.response.data.month_id))

      for (let item in items) {
        item = items[item]

        // TODO: create a function for empty-zero columns
        item.charge_01 = 0
        item.charge_02 = 0
        item.lawyer_charge = 0
        item.cr_note_interest = 0
        item.payment_0 = 0

        item.period_total = item.value
        for (const index in item.payments) {
          const payment = item.payments[index]
          item[`payment_${index}`] = payment.value
          item[`payment_${index}_date`] = payment.payment_date
          item.payment_observations = (item.payment_observations || '') + (payment.description ? `Pago ${parseInt(index) + 1}: ${payment.description} \n` : '') // TODO: find a better way
          item.period_total -= payment.value
        }

        for (const index in item.db_notes) {
          const db_note = item.db_notes[index]
          item[`db_note_${index}`] = db_note.value
          item.note_observations = (item.note_observations || '') + (db_note.description ? `DB Nota ${parseInt(index) + 1}: ${db_note.description} \n` : '') // TODO: find a better way
          item.period_total += db_note.value
        }

        for (const index in item.cr_notes) {
          const cr_note = item.cr_notes[index]
          item[`cr_note_${index}`] = cr_note.value
          item.note_observations = (item.note_observations || '') + (cr_note.description ? `CR Nota ${parseInt(index) + 1}: ${cr_note.description} \n` : '') // TODO: find a better way
          item.period_total -= cr_note.value
        }

        item.invoice_projected += item.period_total // invoice_projected === ifi_total  // TODO: Make this better!!!

        // TODO: create a function (Admon + Charges) ?
        for (const index in item.admon_details) {
          const admon_detail = item.admon_details[index]

          if (admon_detail.imputation) {
            admon_columns[admon_detail.imputation.name] = admon_detail.imputation.name
            item['adm_' + admon_detail.imputation.name] = (item['adm_' + admon_detail.imputation.name] || 0) + admon_detail.value
            item.invoice_projected += admon_detail.value
          }
        }

        for (const index in item.charges) {
          const charge = item.charges[index]
          if (charge.imputation) {
            charges_columns[charge.type][charge.imputation.name] = charge.imputation.name
            item[`${charge.type}_` + charge.imputation.name] = (item[`${charge.type}_` + charge.imputation.name] || 0) + charge.value
            item.invoice_projected += charge.value
          }
        }
      }

      // individuales AAP APQ ACU - FAC

      // Payments
      let period_total_formula = '[value]:0'
      let style = { fill: { fgColor: { rgb: 'c9d8f5' } } }
      for (let i = 0; i < config.min_payments; i++) {
        period_total_formula += ` - [payment_${i}]:0`
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: `payment_${i}`, label: this.$t('Payment') + ` ${i + 1}`, style })
        this.dynamic_fields.push({ key: `payment_${i}_date`, default: '', label: this.$t('Payment date') + ` ${i + 1}`, style })
      }
      this.dynamic_fields.push({ key: `payment_observations` })

      // DB Notes
      for (let i = 0; i < config.min_db_notes; i++) {
        period_total_formula += ` - [db_note_${i}]:0`
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: `db_note_${i}`, label: this.$t('DB note') + ` ${i + 1}`, style })
      }

      // CR Notes
      for (let i = 0; i < config.min_cr_notes; i++) {
        period_total_formula += ` + ([cr_note_${i}]:0)`
        this.dynamic_fields.push({ ...this.helpers.currencyValue, key: `cr_note_${i}`, label: this.$t('CR note') + ` ${i + 1}`, style })
      }

      period_total_formula += ` - [cr_note_interest]:0`
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: `cr_note_interest`, style }) // TODO: check Excel style
      this.dynamic_fields.push({ key: `note_observations`, _style: 'white-space: nowrap; width: 300px;', _classes: 'note-width' }) // TODO: check Excel style

      // total_period === value - payments + db_notes + (cr_notes + cr_note_interest)
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'period_total', formula: period_total_formula })
      let invoice_projected_formula = `[period_total]:0`

      invoice_projected_formula += this.applyOrdering(admon_columns, 'adm', '', ' + XXX', { fill: { fgColor: { rgb: 'ffc0ec' } } })
      invoice_projected_formula += this.applyOrdering(config.form_imputations, 'ifi', '', ' + XXX')

      invoice_projected_formula += ' + [charge_01]:0'
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'charge_01', label: 'Cobro 1', style }) // TODO: English ?
      invoice_projected_formula += ' + [charge_02]:0'
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'charge_02', label: 'Cobro 2', style }) // TODO: English ?
      invoice_projected_formula += ' + [lawyer_charge]:0'
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'lawyer_charge', label: 'Honorarios abogado', style }) // TODO: English ?

      invoice_projected_formula += this.applyOrdering(charges_columns['CEX'], 'CEX', '(CEX) ', ' + XXX', style) // TODO: use variables
      invoice_projected_formula += this.applyOrdering(charges_columns['CBP'], 'CBP', '(CBP) ', ' + XXX', style) // TODO: use variables
      invoice_projected_formula += this.applyOrdering(charges_columns['CBR'], 'CBR', '(CBR) ', ' + XXX', style) // TODO: use variables

      this.dynamic_fields.push({ key: 'detail_charges', label: 'Observaciones cobros', style }) // TODO: English ?
      this.dynamic_fields.push({ key: 'generate_interest', label: 'Generar intereses', style }) // TODO: English ?
      this.dynamic_fields.push({ key: 'marking', label: 'Marcación deuda', style }) // TODO: English ?

      // invoice_projected === total_period + (dynamic_admin) + (form_imputation) + (cobros1 + cobros2) + (lawyer_charge) + (charges)
      this.dynamic_fields.push({ ...this.helpers.currencyValue, key: 'invoice_projected', label: 'Valor proyectado', formula: invoice_projected_formula, style: { fill: { fgColor: { rgb: 'f8ffc0' } } } })

      return items
    },

    applyOrdering(dynamic_fields, prefix, label, formula, style) {
      let new_formula = ''
      const dynamic_fields_array = Object.keys(dynamic_fields)
      for (const imputation of this.$refs.crud_table.response.data.imputation_order) {
        if (dynamic_fields_array.indexOf(imputation.name) >= 0) {
          this.dynamic_fields.push({ ...this.helpers.currencyValue, key: `${prefix}_${imputation.name}`, label: (label || '') + imputation.name, style }) // TODO: Capitalize ?
          if (formula) new_formula += formula.replace('XXX', `[${prefix}_${imputation.name}]:0`)
        }
      }
      return new_formula
    },

    // TODO: validate configs
    addDynamicFields(field_config, excel_config) {
      this.dynamic_fields.push({ ...field_config, ...excel_config })
    }
  }
}
</script>

<style>
.note-width {
  width: 300px;
  white-space: nowrap;
}
</style>
