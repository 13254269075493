var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          { key: 'month_id', _classes: 'hide' },
          _vm.helpers.building,
          _vm.getHelperObject('building_type', 'building.building_type_id'),
          _vm.getHelperObject('property_unit', 'building.property_unit_id'),
          _vm.helpers.ownerName,
          'consecutive',
          Object.assign({}, this.helpers.thousandValue, {key: 'value'}),
          Object.assign({}, this.helpers.thousandValue, {key: 'discount'}),
          Object.assign({}, this.helpers.thousandValue, {key: 'discount_value'}) ].concat( _vm.dynamic_fields
        ),"config":{
          name: 'invoice',
          url: 'admin/invoices/formats',
          route: '/admin/invoicing/formats',
          display_name: 'Planilla de Facturación',
          //params: { _whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units' },
          filters: [{ key: 'month_id', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData }], //, { key: 'imputation_name', label: 'Imputation' }, { key: 'building_code', use: 'building.identifier' }, 'building_type', 'property_unit', { key: 'grouper_code', use: 'grouper.identifier' }, 'owner_name'],
          noCreate: true,
          noOptions: 'hide',
          parseItems: _vm.parseItems
        },"sorterValue":{ column: 'consecutive', asc: false }},on:{"dataChanged":_vm.onDataChanged,"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }